.App {
}

.App-header {
  text-align: center;
  background-color: saddlebrown;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-main {
  text-align: left;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
}

.App ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.App ul>li {
  border-radius: 10px;
  background-color: bisque;
  margin: 20px;
  padding: 10px;
  text-align: left;
  min-height: 10vh;
}

.App textarea {
  display: block;
  box-sizing: border-box;

  width: 100%;
}

.App-footer {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
